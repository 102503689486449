.overviewContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  margin-top: 24px;
}

.overviewContainer > * {
  height: 100%;
  min-height: 250px;
}

.mediaMixCard {
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  /* height: 100%; */
}

.mediaMixCard :global(.ant-card-body) {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.mediaContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mediaItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-bottom: 10px; */
}

.mediaItem:last-child {
  margin-bottom: 0;
}

.mediaLabel {
  display: flex;
  align-items: center;
  gap: 8px;
}

.mediaDot {
  width: 6px;
  height: 19px;
  border-radius: 2px;
}

.mediaStats {
  display: flex;
  align-items: center;
  gap: 16px;
}

.mediaCount {
  font-weight: 600;
  min-width: 60px;
  text-align: right;
}

.mediaPercentage {
  color: #666;
  min-width: 60px;
  text-align: right;
}

.progressContainer {
  margin-top: 24px;
}

.progressContainer :global(.ant-progress-outer .ant-progress-inner) {
  background-color: #f5f5f5;
  /* border-radius: 12px; */
}

.progressContainer :global(.ant-progress-bg) {
  /* border-radius: 12px; */
}

.cardTitle {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 24px;
}