.backToLogin {
    color: #f16434;
    text-decoration: underline;
    display: inline-block;
    cursor: pointer;
}
.requiredAsterisk {
    color: #f16434;
}

.searchButton {
    border: 1px solid #f16434;
    /* width: -webkit-fill-available;
    width: -moz-available; */
    border-radius: 2px;
    background: #f16434;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1.12px;
    height: 48px;
    cursor: pointer;
}

.searchButton:hover {
    background-color: #ffffff;
    color: #f16434;
    border: 1px solid #f16434;
}

.searchButton:disabled {
    cursor: not-allowed;
    /* Indicate it's not clickable */
    opacity: 0.6;
    background-color: #B3B3B3;
    color: #ffffff;
    border: 1px solid #B3B3B3;
    /* Dim the button to indicate disabled state */
}

/* Prevent hover effect when the button is disabled */
.searchButton:disabled:hover {
    background-color: #B3B3B3;
    color: #ffffff;
    border: 1px solid #B3B3B3;
}