.styledCard {
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
  background: white;
}

.metricItem {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}

.metricItem:last-child {
  margin-bottom: 0;
}

.metricItem::before {
  content: '•';
  font-size: 18px;
  font-weight: bold;
  margin-right: 8px;
  line-height: 1;
  color: #f16434;
}

.metricValue {
  font-weight: 600;
  color: #f16434;
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.copyButton {
  cursor: pointer;
  background: none !important;
  border: none;
  width: 40px;
  align-items: center;
  justify-content: center;
  position: relative;
  display: flex;
  padding: 8px;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.copyButton img {
  width: 20px;
  height: 20px;
}

.copyButton:hover {
  box-shadow: 0 4px 12px rgba(241, 100, 52, 0.25);
  background: rgba(250, 64, 2, 0.08) !important;
}

.grayText {
  color: #666666;
} 