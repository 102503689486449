.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.graphContainer {
    height: 300px;
    width: 100%;
}

.landingPagesCard {
    background: #fff;
    border-radius: 8px;
    height: 100%;
    overflow: hidden;
}

.landingPagesList {
    height: 100%;
    overflow-y: auto;
    padding: 0 16px;
}

.landingPageItem {
    padding: 12px 0;
    border-bottom: 1px solid #f0f0f0;
    cursor: pointer;
}

.landingPageItem:last-child {
    border-bottom: none;
}

.landingPageHeader {
    display: flex;
    align-items: center;
    gap: 12px;
}

.checkboxContainer {
    display: flex;
    align-items: center;
    gap: 8px;
}

.checkbox {
    display: flex;
    align-items: center;
}

.checkbox input {
    width: 16px;
    height: 16px;
    cursor: pointer;
    accent-color: #f16434;
}

.colorDot {
    width: 6px;
    height: 19px;
    border-radius: 2px;
}

.urlContainer {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 8px;
    min-width: 0;
}
.urlContainer:hover .copyIcon {
    opacity: 1;
}
.url {
    color: #262626;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  
}

.url:hover {
    color: #f16434;
    text-decoration: underline;
}

.copyButton {
    background: none;
    border: none;
    padding: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.copyIcon {
    opacity: 0;
    transition: opacity 0.2s ease;
    cursor: pointer;
    color: #eb7953;
    margin-left: 8px;
}

.rightSection {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-left: auto;
}

.count {
    margin-left: auto;
    color: #666;
    font-size: 14px;
    font-weight: 500;
    padding: 4px 12px;
}

.barContainer {
    width: 174px;
    height: 12px;
    background: #F5F5F5;
    border-radius: 2px;
    overflow: hidden;
    margin: 0 16px;
    display: flex;
    flex-direction: row;
}
