.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.graphContainer {
    height: 300px;
    width: 100%;
    padding: 20px;
}

.mediaMixCard {
    height: 100%;
    overflow: hidden;
}

.mediaList {
    height: 100%;
    overflow-y: auto;
    padding: 0 16px;
}

.mediaLabelContainer {
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1;
}

.mediaLabel {
    cursor: pointer;
    color: #262626;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
}

.copyButton {
    cursor: pointer;
    background: none !important;
    border: none;
    width: 40px;
    align-items: center;
    justify-content: center;
    position: relative;
}

.copyIcon {
    opacity: 0;
    transition: opacity 0.2s ease;
    cursor: pointer;
    color: #eb7953;
    margin-left: 8px;
}

.mediaLabelContainer:hover .copyIcon {
    opacity: 1;
}

.leftSection {
    display: flex;
    align-items: center;
    gap: 12px;
    flex: 1;
}

.mediaItem {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    cursor: pointer;
    border-bottom: 1px solid #f0f0f0;
}

.mediaItem:last-child {
    border-bottom: none;
}

.mediaIcon {
    color: #666;
    text-decoration: none;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
}
.mediaIcon:hover {
    cursor: pointer;
}


.count {
    color: #666666;
    font-size: 14px;
    min-width: 40px;
    text-align: right;
}

.checkboxContainer {
    display: flex;
    align-items: center;
    gap: 8px;
}

.checkbox {
    display: flex;
    align-items: center;
}

.checkbox input {
    width: 16px;
    height: 16px;
    cursor: pointer;
    accent-color: #f16434;
}

.colorDot {
    width: 6px;
    height: 19px;
    border-radius: 2px;
    flex-shrink: 0;
}

.rightSection {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-left: auto;
}

.barContainer {
    width: 174px;
    height: 12px;
    background: #F5F5F5;
    border-radius: 2px;
    overflow: hidden;
    margin: 0 16px;
    display: flex;
    flex-direction: row;
}

.bar {
    height: 100%;
    border-radius: 2px;
    transition: width 0.3s ease;
    text-align: right;
    display: flex;
}

.mediaCount {
    color: #666;
    font-size: 14px;
    font-weight: 500;
    min-width: 60px;
    text-align: right;
}

.imageBar {
    background: #4A9FFF;
}

.videoBar {
    background: #7B2BFF;
}

.carouselBar {
    background: #FF6B6B;
}

.legendContainer {
    display: flex;
    justify-content: flex-start;
    gap: 24px;
    padding: 16px;
    background: #fff;
    border-radius: 8px;
}

.legendItem {
    display: flex;
    align-items: center;
    gap: 8px;
}

.legendDot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.legendText {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #666;
    font-size: 14px;
}

.legendCount {
    background: #f5f5f5;
    padding: 2px 8px;
    border-radius: 12px;
    font-weight: 500;
}

.statsContainer {
    display: flex;
    gap: 16px;
    margin-bottom: 20px;
}

.statItem {
    display: flex;
    align-items: center;
    gap: 8px;
}

.statIcon {
    display: flex;
    align-items: center;
}

.imageIcon {
    color: #4A9FFF;
}

.videoIcon {
    color: #7B2BFF;
}

.carouselIcon {
    color: #FF6B6B;
}

.statLabel {
    font-size: 14px;
    color: #666;
}

.statCount {
    font-size: 14px;
    font-weight: 500;
    color: #333;
    margin-left: 4px;
    background: #f5f5f5;
    padding: 2px 8px;
    border-radius: 12px;
}
