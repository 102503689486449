.mainCard {
  background: white;
  border-radius: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  margin-bottom: 24px;
}

.mainCard :global(.ant-card-body) {
  padding: 24px;
}

.brandHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.brandInfo {
  display: flex;
  align-items: center;
  gap: 16px;
}

.brandLogo {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.brandMeta {
  display: flex;
  flex-direction: column;
}



.tabsContainer {
  margin-bottom: 24px;
}

.tabsContainer :global(.ant-tabs-nav) {
  margin-bottom: 0;
}

.tabsContainer :global(.ant-tabs-tab) {
  padding: 8px 0;
  margin: 0 24px 0 0;
  font-size: 14px;
  color: #666;
}

.tabsContainer :global(.ant-tabs-tab-active .ant-tabs-tab-btn) {
  color: #f16434;
  font-weight: 600;
}

.tabsContainer :global(.ant-tabs-tab:hover) {
  color: #f16434;
}

.tabsContainer :global(.ant-tabs-ink-bar) {
  background: #f16434;
}

.buttonContainer {
  position: fixed;
  bottom: 32px;
  right: 32px;
  transform: translate(50%, 50%);
  align-items: center;
  height: 32px;
  width: 32px;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  opacity: 1;
  background: #f16434;
  border-radius: 5rem;
  display: flex;
}

.buttonContainer:hover {
  opacity: 0.9;
}

.activeAds {
  text-align: end;
  color: #f16434;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}