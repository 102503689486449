.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}


.headlinesCard {
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    height: 100%;
}

.headlinesList {
    height: 100%;
    overflow-y: auto;
    padding: 16px;
}

.headlineItem {
    padding: 16px 0;
    border-bottom: 1px solid #f0f0f0;
}

.headlineItem:last-child {
    border-bottom: none;
}

.headlineHeader {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
}

.checkboxContainer {
    display: flex;
    align-items: center;
    gap: 8px;
}

.checkbox {
    display: flex;
    align-items: center;
}

.checkbox input {
    width: 16px;
    height: 16px;
    cursor: pointer;
    accent-color: #f16434;
}

.colorDot {
    width: 6px;
    height: 19px;
    border-radius: 2px;
}

.headlineContainer {
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1;
}
.headlineContainer:hover .copyIcon {
    opacity: 1;
}

.headline {
    color: #666;
    text-decoration: none;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
}

.headline:hover {
    cursor: pointer;
}

.count {
    margin-left: auto;
    color: #666;
    font-size: 14px;
    font-weight: 500;
    /* background: #f5f5f5; */
    padding: 4px 12px;
    /* border-radius: 16px; */
} 
.rightSection {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-left: auto;
}
.barContainer {
    width: 174px;
    height: 12px;
    background: #F5F5F5;
    border-radius: 2px;
    overflow: hidden;
    margin: 0 16px;
    display: flex;
    flex-direction: row;
}
.copyIcon {
    opacity: 0;
    transition: opacity 0.2s ease;
    cursor: pointer;
    color: #eb7953;
    margin-left: 8px;
}

.adCopyContainer:hover .copyIcon {
    opacity: 1;
}
.copyButton {
    cursor: pointer;
    background: none !important;
    border: none;
    width: 40px;
    align-items: center;
    justify-content: center;
    position: relative;
    /* Ensures consistent alignment */
  }
