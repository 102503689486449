.passwordResetWrapper {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 86px);

  .bold {
    font-weight: bold;
  }

  .NP {
    border: 2px solid #ff5e29;
    padding: 2px;
    font-weight: bold;
    color: #ff5e29;
  }
}

.loginFormCard {
  background-color: #ffffff;
  width: 100%;
  height: fit-content;
  max-width: 27.5rem;
  box-shadow: 0px 10px 32px rgba(32, 32, 32, 0.04);
  border-radius: 4px;
  padding: 2.5rem;
  gap: 0.5rem;

  .loginForm {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .inputWrapper {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
  }

  .label {
    font-weight: bold;
  }

  .input {
    padding: 0.625rem;
    border: 1px solid #d1d7d9;
    border-radius: 2px;
    outline: #4285f4;
  }

  .fogotPasswordWrapper {
    width: 100%;
    display: flex;
    justify-content: end;
  }

  .submitButton {
    background-color: #f16434;
    padding: 14px 70px;
    border: 1px solid transparent;
    border-radius: 2px;
    text-transform: uppercase;
    cursor: pointer;
  }
  .searchButton {
    border: 1px solid #f16434;
    /* width: -webkit-fill-available;
    width: -moz-available; */
    border-radius: 2px;
    background: #f16434;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1.12px;
    height: 48px;
    cursor: pointer;
}

.searchButton:hover {
    background-color: #ffffff;
    color: #f16434;
    border: 1px solid #f16434;
}

  .submitButton:hover {
    /* background-color: #a3b0b3; */
    /* background-color: #f16434; */
    /* color: #f5f5f5; */
  }

  .seperator {
    margin: auto;
  }
}

.forgotPassword {
  color: #f16434;
  padding-bottom: 1px;
  padding: 0;
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  position: relative;
  cursor: pointer
}

.forgotPassword::after {
  content: '';
  position: absolute;
  border-bottom: 1px solid tomato;
  top: 100%;
  left: 0;
  right: 0;
}

.registerWrapper {
  margin-top: 1rem;
  font-size: 14px;
  font-weight: 400;
}

.googleButtonContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.googleImageContianer {
  width: 35px;
  height: 35px;
  background-color: #ffff;
  margin-right: 5px;
  margin-left: -4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
}

.googleButton {
  background-color: #4285f4;
  color: #ffff;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 700;
  width: fit-content;
  display: flex;
  align-items: center !important;
  justify-content: center;
  border-radius: 2px;
  cursor: pointer;
}

.bold {
  font-weight: 700;
}

.checkboxText {
  font-size: 12px;
  line-height: 16px;
}

.checkboxContainer {
  display: flex;
  align-items: center;
}

.required,
.error {
  color: #f16434;
}

.inputHeader {
  font-weight: bold;
  font-size: 16px;
}

.requiredAsterisk {
  color: #f16434;
}

.errorMessage {
  color: red;
  font-size: 12px;
  margin-top: 4px;
}

.errorInput {
  border-color: red !important;
}

.inputHeader {
  font-weight: bold;
  font-size: 16px;
}

.registerHere {
  color: #f16434;
  text-decoration: underline;
  display: inline-block;
  cursor: pointer;
}

/* .registerHere:hover {
  color: #f16434;
  text-decoration: underline;
  cursor: pointer;
  font-size: 13px;
} */