.adsActivityCard {
  height: 100%;
  overflow: hidden;
}

.activityList {
  height: 100%;
  overflow-y: auto;
  padding: 0 16px;
}

.mediaLabelContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
}

.mediaLabel {
  cursor: pointer;
  color: #262626;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.copyButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  margin-left: 4px;
}

.copyIcon {
  opacity: 0;
  transition: opacity 0.2s ease;
  cursor: pointer;
  color: #eb7953;
  margin-left: 8px;
}

.mediaLabelContainer:hover .copyIcon {
  opacity: 1;
}
.leftSection {
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1;
}

.activityItem {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  cursor: pointer;
  border-bottom: 1px solid #f0f0f0;
}

.activityItem:last-child {
  border-bottom: none;
}

.count {
  color: #666666;
  font-size: 14px;
  min-width: 40px;
  text-align: right;
}

.checkboxContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.checkbox {
  display: flex;
  align-items: center;
}

.checkbox input {
  width: 16px;
  height: 16px;
  cursor: pointer;
  accent-color: #f16434;
}

.colorDot {
  width: 6px;
  height: 19px;
  border-radius: 2px;
  flex-shrink: 0;
} 