.container {
  margin-top: 20px;
}

.hooksCard {
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.hooksList {
  height: 100%;
  overflow-y: auto;
  padding: 0 16px;
}

.hookItem {
  padding: 16px 0;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
}

.hookItem:last-child {
  border-bottom: none;
}

.hookHeader {
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1;
}

.checkboxContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.checkbox {
  display: flex;
  align-items: center;
}
.checkbox input {
  width: 16px;
  height: 16px;
  cursor: pointer;
  accent-color: #f16434;
}

.colorDot {
  width: 6px;
  height: 19px;
  border-radius: 2px;
  flex-shrink: 0;
} 

.hookContainer {
 
  flex: 1;
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 0;
}

.hook {
  font-size: 14px;
  color: #666;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.copyButton {
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  opacity: 0;
  transition: opacity 0.2s;
}

.hookItem:hover .copyButton {
  opacity: 1;
}

.copyIcon {
  width: 16px;
  height: 16px;
}

.rightSection {
  display: flex;
  align-items: center;
  gap: 16px;
  min-width: 80px;
}

.count {
  color: #666666;
  font-size: 14px;
  min-width: 40px;
  text-align: right;
}
/* Chart styles */
.chartContainer {
  height: 400px;
  width: 100%;
}

.tooltipContainer {
  padding: 12px;
  background: white;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  min-width: 200px;
  max-width: 550px;
}

.tooltipContent {
  max-height: 200px;
  overflow: hidden;
}

.tooltipItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  font-size: 13px;
  color: #333;
}

.tooltipText {
  flex: 1 1 auto;
  padding-right: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.tooltipValue {
  flex: 0 0 auto;
  min-width: 40px;
  text-align: right;
  font-weight: 500;
} 